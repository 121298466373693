<template>
  <div class="login container-fluid min-vh-100 whitebg">
    <vue-headful
      title="Install-HC - Me connecter"
      description="Me conntecter au site Install-HC"
    />
    <div>
      <nav role="navigation">
        <div id=header class="float-right">
          <InstallHC :key="componentKey"/>
        </div> 
        <Menu :key="componentKey" :authenticated="authStatus"/>
      </nav>
    </div>
    <div class="inputForm">
      <h1 v-if="forget">Mot de passe oublié</h1>
      <h1 v-else>Merci de vous authentifier</h1>            
      <form class="shadow" id="authentication" @submit="checkForm" method="post" >

      <p v-if="errors.length">
        <b>Merci de corriger les erreurs suivantes :</b>
        <ul>
          <li v-for="error in errors" v-bind:key="error.id">{{ error }}</li>
        </ul>
      </p>

      <p v-if="token == 'true' && this.forget == false">
        <ul>
          <li class="blue">Authentification effectuée avec succès !</li>
          <li class="blue">Vous allez être redirigé à l'accueil du site.</li>
        </ul>
      </p>

      <p v-if="token == 'true' && this.forget == true">
        <ul>
          <li class="blue">Un e-mail vient de vous être envoyé.</li>
          <li class="blue">Merci de le consulter.</li>
        </ul>
      </p>

      <p>
        <label for="login">Login</label>&nbsp;
        <input id="login" v-model="login" type="text" name="login" :disabled="disabled == 1">
      </p>

      <p v-if="forget">
        <label for="email">Adresse e-mail</label>&nbsp;
        <input id="email" v-model="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" title="Merci de saisir une adresse e-mail valide !" name="email" :disabled="disabled == 1">
      </p>

      <p v-else>
        <label for="passwd">Mot de passe</label>&nbsp;
        <input id="passwd" v-model="passwd" type="password" name="passwd" :disabled="disabled == 1">
      </p>    

      <p>
        <input type="submit" value="Valider" :disabled="disabled == 1">
      </p>

      <p v-if="forget == false">
        <a href="#" @click="forgottenPwd">Mot de passe oublié</a>
      </p>
      
      <p v-if="forget == false">
        <router-link to="/createAccount">Créer un compte</router-link>
      </p>

      </form>
    </div>    
  </div>  
</template>

<script>
// @ is an alias to /src
import Menu from '@/components/Menu.vue'
import InstallHC from '@/components/InstallHC.vue'
export default { 
  name: 'login',
  components: {
    Menu,
    InstallHC
  },
  data() {         
    return {
      json: null,                 
      login: null,
      passwd: null,
      email: null,
      token: "false",
      errors: [],
      componentKey: 0,
      disabled: 0,
      authStatus: false,
      forget: false,
    }    
  },  
  methods:{
    forgottenPwd: function() {
      this.forget = true
    },
    forceRerender: function() {
      this.componentKey += 1
      this.authStatus = true       
      setTimeout(() => {
        location.reload(true)  
      }, 500);
      if( this.forget == true) {        
        this.$router.push('/login')
      } else {
        this.$router.push('/')
      }
    },
    checkForm: function (e) {
      var apiUrl = "/backend/get/form/login"
      this.errors = [];

      if (!this.login) {
        this.errors.push('Login requis !');
      }      
      if (!this.passwd && this.forget == false) {
        this.errors.push('Mot de passe requis !');
      }

      if(!this.email && this.forget == true ) {
        this.errors.push('Adresse e-mail requise !');
      }

      if (this.login && this.passwd && this.forget == false) {        
        fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({login: this.login, passwd: this.passwd})
        })
        .then(res => res.json())
        .then(res => {
          this.token = res.token
          if (res.error) {
            this.errors.push(res.error);
          } else {            
            this.disabled=1
            setTimeout(() => {
              this.forceRerender()
            }, 1200); 
          }
        });
      }

      if (this.login && this.email && this.forget == true) {        
        fetch(apiUrl,{
          method: "POST",
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({login: this.login, email: this.email})
        })
        .then(res => res.json())
        .then(res => {
          this.token = res.token
          if (res.error) {
            this.errors.push(res.error);
          } else {            
            this.disabled=1
            setTimeout(() => {
              this.forceRerender()
            }, 1500); 
          }
        });
      }

      e.preventDefault();
    }
  }   
}

</script>

<style scoped>
.debug {
  display: none;
}

.login {
  margin:0 auto;  
  padding:0;
}

h1 {
  font-size:1.05em;
  font-weight:bold;
  text-align: center;
  margin-top:10px;
}

a {
  font-weight: bold;
  font-size:1.1rem;
}

a:hover
{
  text-decoration:none;  
}

ul {
  list-style:none;
  color:red;
  font-weight: bold;
  padding-left:5px;
}


#authentication {
  text-align:left;
  margin-left:20px;
  padding:10px;
  border-radius:5px;
  min-width:100px;    
  max-width:300px;
}

label {
  width:100px;
}

.blue {
  color: #007bff;
}

</style>